import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
//import { StaticPageComponent } from '../../src/app/modules/static-page/static-page.component';

const routes: Routes = [
  //{ path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
  //  component: StaticPageComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/static-page/static-page.module#StaticPageModule'
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
