import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  status: string;
  role_id: any;
}

export const ROUTES: RouteInfo[] = [
  {
    path: "/users",
    title: "Users",
    icon: "../../../assets/img/icons/common/user-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/forms",
    title: "Forms",
    icon: "../../../assets/img/icons/common/sidebar-icon-form.png",
    class: "",
    status: "0",
    role_id: [1]
  },
 
  {
    path: "/price-list",
    title: "Price list forms",
    icon: "../../../assets/img/icons/common/price-list-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/contents",
    title: "Contents",
    icon: "../../../assets/img/icons/common/content-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/general-settings",
    title: "General Settings",
    icon: "../../../assets/img/icons/common/general-setting-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  {
    path: "/admins",
    title: "system administrators",
    icon: "../../../assets/img/icons/common/system-adminator-icon.png",
    class: "",
    status: "0",
    role_id: [1]
  },
  // {
  //   path: "/change-password",
  //   title: "Change Password",
  //   icon: "ni ni-lock-circle-open text-orange",
  //   class: "",
  //   status: "0",
  //   role_id: [1]
  // },
  {
    path: "/login",
    title: "Conecte-se",
    icon: "ni-key-25 text-info",
    class: "",
    status: "1",
    role_id: [1]
  },
  {
    path: "/register",
    title: "Register",
    icon: "ni-circle-08 text-pink",
    class: "",
    status: "1",
    role_id: [1]
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  currentUser: any = [];

  constructor(private router: Router) { 
    let currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(currentUser);
  }

  ngOnInit() {
    let that = this;
    this.menuItems = ROUTES.filter(function (element) {
      let index = element.role_id.indexOf(that.currentUser.role_id);
      if(index !== -1){
        return element;
      }
    });

    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  /**
   * LogOut User Session
   */
    logout() {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
}
