import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './common/Constants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(private translate: TranslateService) {
    const defaultLanguage = localStorage.getItem("lang") ? localStorage.getItem("lang") : Constants.defaultLanguage;
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
  }
}
